var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('a-table',{attrs:{"rowKey":"_id","columns":_vm.columns,"data-source":_vm.userList},scopedSlots:_vm._u([{key:"biz",fn:function(user){return _c('a-tag',{},[_vm._v(_vm._s(user.biz))])}},{key:"action",fn:function(user){return _c('a-space',{},[_c('a',{attrs:{"type":"link"},on:{"click":function($event){return _vm.toDetail(user._id)}}},[_vm._v("详情")]),_c('a',{attrs:{"type":"link"},on:{"click":function($event){return _vm.editUser(user)}}},[_vm._v("编辑")]),_c('a',{staticStyle:{"color":"red"},attrs:{"type":"link"},on:{"click":function($event){return _vm.delUser(user)}}},[_vm._v("删除")])])}}])}),_c('a-modal',{attrs:{"title":"客户","visible":_vm.addVisible},on:{"cancel":_vm.hideModal,"ok":_vm.handleSubmit}},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"客户名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'name',
            {
              rules: [
                {
                  required: true,
                  message: '请输入客户名称',
                } ],
            } ]),expression:"[\n            'name',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: '请输入客户名称',\n                },\n              ],\n            },\n          ]"}],attrs:{"placeholder":"请输入客户名称"}})],1),_c('a-form-item',{attrs:{"label":"Logo"}},[_c('a-upload',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'logo',
            {
              valuePropName: 'fileList',
              getValueFromEvent: _vm.normFile,
              rules: [
                {
                  required: true,
                  message: '请上传Logo',
                } ],
            } ]),expression:"[\n            'logo',\n            {\n              valuePropName: 'fileList',\n              getValueFromEvent: normFile,\n              rules: [\n                {\n                  required: true,\n                  message: '请上传Logo',\n                },\n              ],\n            },\n          ]"}],attrs:{"accept":"image/*","action":"https://xuming.dynamicsales.cn/users/upload","name":"img","list-type":"picture"}},[_c('a-button',[_c('a-icon',{attrs:{"type":"upload"}}),_vm._v(" 请上传Logo ")],1)],1)],1),_c('a-form-item',{attrs:{"label":"客户地址"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'addr',
            {
              rules: [
                {
                  required: true,
                  message: '请输入客户地址',
                } ],
            } ]),expression:"[\n            'addr',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: '请输入客户地址',\n                },\n              ],\n            },\n          ]"}],attrs:{"placeholder":"请输入客户地址"}})],1),_c('a-form-item',{attrs:{"label":"联系人"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'contacts',
            {
              rules: [
                {
                  required: true,
                  message: '请输入联系人',
                } ],
            } ]),expression:"[\n            'contacts',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: '请输入联系人',\n                },\n              ],\n            },\n          ]"}],attrs:{"placeholder":"请输入联系人"}})],1),_c('a-form-item',{attrs:{"label":"联系方式"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'tel',
            {
              rules: [
                {
                  required: true,
                  message: '请输入客户联系方式',
                } ],
            } ]),expression:"[\n            'tel',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: '请输入客户联系方式',\n                },\n              ],\n            },\n          ]"}],attrs:{"placeholder":"请输入客户联系方式"}})],1),_c('a-form-item',{attrs:{"label":"客户所属区域"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'area',
            {
              rules: [
                {
                  required: true,
                  message: '请输入客户所属区域',
                } ],
            } ]),expression:"[\n            'area',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: '请输入客户所属区域',\n                },\n              ],\n            },\n          ]"}],attrs:{"placeholder":"请输入客户所属区域"}})],1),_c('a-form-item',{attrs:{"label":"账号额度"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'account',
            {
              initialValue: 0,
              rules: [{ validator: _vm.checkNum }],
            } ]),expression:"[\n            'account',\n            {\n              initialValue: 0,\n              rules: [{ validator: checkNum }],\n            },\n          ]"}],staticClass:"block",attrs:{"placeholder":"请输入账号余额"}})],1),_c('a-form-item',{attrs:{"label":"信用额度"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'credit',
            {
              initialValue: 0,
              rules: [{ validator: _vm.checkNum }],
            } ]),expression:"[\n            'credit',\n            {\n              initialValue: 0,\n              rules: [{ validator: checkNum }],\n            },\n          ]"}],staticClass:"block",attrs:{"placeholder":"请输入信用额度"}})],1),_c('a-form-item',{attrs:{"label":"业务员"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['biz']),expression:"['biz']"}],attrs:{"placeholder":"请分配业务员"}},_vm._l((_vm.businessList),function(item){return _c('a-select-option',{key:item.name,attrs:{"value":item.name}},[_vm._v(_vm._s(item.name)+" ")])}),1)],1)],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fr title"},[_c('span',[_vm._v("客户管理")]),_c('div',{staticClass:"blank"})])}]

export { render, staticRenderFns }