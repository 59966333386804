

















































import {Component, Vue} from 'vue-property-decorator'
import {get, post} from "@/utils/request";

interface BusinessInterface {
  _id: string;
  name: string;
  customers: BusinessInterface[];
}

@Component({
  name: 'Business',
})
export default class Business extends Vue {
  addVisible = false
  form: any;
  userList = []
  columns = [
    {title: '用户名', dataIndex: 'name', key: 'name'},
    {title: '联系方式', dataIndex: 'tel', key: 'tel'},
    {title: '负责客户', key: 'customers', scopedSlots: {customRender: 'customers'}},
    {
      title: '操作',
      key: 'operation',
      fixed: 'right',
      width: 100,
      scopedSlots: {customRender: 'action'},
    },
  ];

  created() {
    this.form = this.$form.createForm(this, {name: "biz"})
  }

  mounted() {
    this.listUser()
  }

  listUser() {
    get('/user/list?type=biz').then(data => {
      this.userList = data.user
    })
  }

  handleSubmit() {
    this.form.validateFields((err, values: BusinessInterface) => {
      if (!err) {
        post('/user/add', {...values, type: 'biz'}).then(() => {
          this.$message.success('添加成功')
          this.listUser()
          this.hideModal()
        })

      }
    });
  }


  delUser(user: BusinessInterface) {
    console.log(user);
    this.$confirm({
      title: `确认删除${user.name}吗？`,
      content: '删除后无法恢复',
      onOk: () => {
        post('/user/del', {id: user._id}).then(() => {
          this.$message.success('删除成功')
          this.listUser()
        })

      }
    });
  }

  hideModal() {
    this.addVisible = false
    this.form.resetFields()
  }
}
