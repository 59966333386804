





























import {Component, Vue} from 'vue-property-decorator'
import {get, post} from "@/utils/request";

interface OrderInterface {
  _id: string;
}

@Component({
  name: 'Goods',
  components: {}
})
export default class Goods extends Vue {
  addVisible = false
  form: any;
  editGoodsId: string | undefined = undefined
  list = []
  STATUS = {
    '-1': '待修改',
    '0': '待审核',
    '1': '审核通过',
    '2': '排产',
    '3': '排单',
    '4': '发货',
    '5': '派车',
  }
  columns = [
    {title: '客户', dataIndex: 'customer', key: 'customer'},
    {title: 'SKU合计', dataIndex: 'customer', key: 'customer'},
    {title: '方数', dataIndex: 'customer', key: 'customer'},
    {title: 'SKU1', dataIndex: 'customer', key: 'customer'},
    {title: '物料合计', dataIndex: 'customer', key: 'customer'},
    {title: '物料1', dataIndex: 'customer', key: 'customer'},
    {title: '物流商', dataIndex: 'customer', key: 'customer'},
    {title: '备注', dataIndex: 'customer', key: 'customer'},
    {
      title: '操作',
      key: 'operation',
      fixed: 'right',
      width: 100,
      scopedSlots: {customRender: 'action'},
    },
  ];

  created() {
    this.form = this.$form.createForm(this, {name: "order"})
  }

  mounted() {
    this.listOrder()
  }

  listOrder() {
    get('/order/list').then(data => {
      this.list = data.order
    })
  }


  detail(order: OrderInterface) {
    this.$router.push(`/order-detail?id=${order._id}`)
  }

  delOrder(order: OrderInterface) {
    this.$confirm({
      title: `确认删除吗？`,
      content: '删除后无法恢复',
      onOk: () => {
        post('/order/del', {id: order._id}).then(() => {
          this.$message.success('删除成功')
          this.listOrder()
        })

      }
    });
  }

  hideModal() {
    this.addVisible = false
    this.editGoodsId = undefined
    this.form.resetFields()
  }
}
