





























import loginBgImg from "../assets/login.jpg";
import { login, setToke } from "@/utils/request";
import {Vue, Component} from "vue-property-decorator";
@Component({
  name:'Login'
})
export default class Login extends Vue {
  loginBgImg = loginBgImg;
  fixStyle = "";
  username = "";
  password = "";

  async _login() {
    // 
    login('https://xuming.dynamicsales.cn/auth/login', {username: this.username, password: this.password}).then((data) => {
        console.log(data.data.payload.access_token)
        setToke(data.data.payload.access_token)
        console.log("hello");       
        this.$router.push('/products')
    })
    
  }
}
