var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('div',{staticClass:"info"},[_c('div',[_vm._v("客户名称： "+_vm._s(_vm.customer.name))])]),_c('h3',{staticStyle:{"margin-top":"33px"}},[_vm._v("账号余额")]),_c('a-table',{attrs:{"rowKey":"_id","columns":_vm.columns,"data-source":_vm.flowList},scopedSlots:_vm._u([{key:"action",fn:function(row){return _c('a-space',{},[_c('a',{attrs:{"type":"link"},on:{"click":function($event){return _vm.credit(row)}}},[_vm._v("临时授信")])])}}])}),_c('a-modal',{attrs:{"title":"修改密码","visible":_vm.passwordDialog},on:{"cancel":_vm.hideModal,"ok":_vm.handleSubmit}},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"新密码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'password',
            {
              rules: [
                {
                  required: true,
                  message: '请输入新密码',
                } ],
            } ]),expression:"[\n            'password',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: '请输入新密码',\n                },\n              ],\n            },\n          ]"}],attrs:{"placeholder":"请输入新密码"}})],1),_c('a-form-item',{attrs:{"label":"确认密码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'password2',
            {
              rules: [
                {
                  required: true,
                  message: '请确认密码',
                } ],
            } ]),expression:"[\n            'password2',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: '请确认密码',\n                },\n              ],\n            },\n          ]"}],attrs:{"placeholder":"请确认密码"}})],1)],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fr title"},[_c('span',[_vm._v("客户详情")])])}]

export { render, staticRenderFns }