
















































import {Component, Vue} from 'vue-property-decorator'
import {get, post} from "@/utils/request";

export interface BusinessInterface {
  _id: string;
  time: string;
  content: string;
}

@Component({
  name: 'Notice',
})
export default class Notice extends Vue {
  addVisible = false
  editId: string | undefined = undefined
  form: any;
  datas = []
  columns = [
    {title: '内容', dataIndex: 'content', key: 'content'},
    {title: '添加时间', dataIndex: 'time', key: 'time'},
    {title: '角色', dataIndex: 'type', key: 'type'},
  ];


  created() {
    this.form = this.$form.createForm(this, {name: "customer"})
  }

  mounted() {
    this.list()
    
  }

  list(){
    get('/notice/list').then(data => {
      this.datas = data.list
    })
  }

  handleSubmit() {
    this.form.validateFields((err, values: BusinessInterface) => {
      if (!err) {
        if(this.editId){
          post('/notice/edit', {id:this.editId,...values,
          }).then(() => {
            this.$message.success('修改成功')
            this.hideModal()
             this.list()
          })

        }else {
          post('/notice/add', {...values}).then(() => {
            this.$message.success('添加成功')
            this.hideModal()
             this.list()
          })

        }

      }
    });
  }


  delUser(user: BusinessInterface) {
    this.$confirm({
      title: `确认删除吗？`,
      content: '删除后无法恢复',
      onOk: () => {
        post('/notice/del', {id: user._id}).then(() => {
          this.$message.success('删除成功')
          this.list()
        })

      }
    });
  }

  hideModal() {
    this.addVisible = false
    this.form.resetFields()
  }

  checkNum(rule, value, callback) {
    if (value >= 0) {
      callback();
      return;
    }
    callback('必须大于等于0');
  }
}
