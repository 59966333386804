var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"fr title"},[_c('span',[_vm._v("公告管理")]),_c('div',{staticClass:"blank"}),_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.addVisible=true;_vm.editId=''}}},[_vm._v("添加公告")])],1),_c('a-table',{attrs:{"rowKey":"_id","columns":_vm.columns,"data-source":_vm.datas},scopedSlots:_vm._u([{key:"biz",fn:function(user){return _c('a-tag',{},[_vm._v(_vm._s(user.biz))])}},{key:"action",fn:function(user){return _c('a-space',{},[_c('a',{attrs:{"type":"link"},on:{"click":function($event){return _vm.toDetail(user._id)}}},[_vm._v("详情")]),_c('a',{attrs:{"type":"link"},on:{"click":function($event){return _vm.editUser(user)}}},[_vm._v("编辑")]),_c('a',{staticStyle:{"color":"red"},attrs:{"type":"link"},on:{"click":function($event){return _vm.delUser(user)}}},[_vm._v("删除")])])}}])}),_c('a-modal',{attrs:{"title":"公告","visible":_vm.addVisible},on:{"cancel":_vm.hideModal,"ok":_vm.handleSubmit}},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"公告内容"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'content',
            {
              rules: [
                {
                  required: true,
                  message: '请输入公告内容',
                } ],
            } ]),expression:"[\n            'content',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: '请输入公告内容',\n                },\n              ],\n            },\n          ]"}],attrs:{"rows":"5","placeholder":"请输入公告内容"}})],1),_c('a-form-item',{attrs:{"label":"角色"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['brand']),expression:"['brand']"}],staticClass:"block",attrs:{"placeholder":"请选择角色"}},_vm._l((['业务员', '客户', '全部']),function(item){return _c('a-select-option',{key:item,attrs:{"value":item}},[_vm._v(_vm._s(item))])}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }