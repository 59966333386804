






















































import {Component, Vue} from 'vue-property-decorator'
import {get, post, del, put} from "@/utils/request";

@Component({
  name:'Home',
})
export default class Home extends Vue {
  collapsed = false
  username=''

  mounted() {
    // get('/info').then(data=>{
    //   this.username=data.user.name
    // }).catch(()=>{
    //   this.$router.replace('/login')
    // })

  }

  link(path: string) {
    this.$router.push(path)
  }

}
