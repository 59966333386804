






















































































import { Component, Vue } from "vue-property-decorator";
import { get, post,  del, put,  exportXLSX, readXLSX } from "@/utils/request";
import { Switch } from "ant-design-vue";

interface ProductsInterface {
  id: string;
  name: string;
  code: string;
  brand: string;
  size: number;
  unit: string;
  expired: string;
  location: string;
  barcode: string; 
  category: string;
  package: string;
  images: [string];
  cover: string;
  bcPrice: object;
  isHot: boolean;
  isNew: boolean;
}

@Component({
  name: "Products",
  components: {},
})

export default class Products extends Vue {
  addVisible = false;
  form: any;
  editProductsId: string | undefined = undefined;
  productsList: ProductsInterface[] = [];
  allProducts: ProductsInterface[] = [];
  id1 = "";
  name = "";
  checkedHot = false;
  checkedNew = false;
  paginatio={};
  columns = [
    { title: "主键ID", dataIndex: "id", width:180, key: "id1" },
    { title: "保质期", dataIndex: "expired",  width:80, key: "expired" },
    { title: "国际码", dataIndex: "barcode",  width:100, key: "barcode" },
    { title: "货品编码", dataIndex: "code", width:100, key: "code" },
    { title: "品牌", dataIndex: "brand",  width:80, key: "brand" },
    { title: "货品名称", dataIndex: "name", width:120, key: "name" },
    { title: "规格", dataIndex: "size",  width:80, key: "size" },
    { title: "包装类型", dataIndex: "package",  width:80, key: "package" },
    { title: "默认单位", dataIndex: "unit",  width:80, key: "unit" },
    { title: "品类", dataIndex: "category",  width:80, key: "category" },
    { title: "价格带", dataIndex: "bcPrice",  width:80,key: "bcPrice" },
    { title: "介绍图片", key: "images",  width:160,  scopedSlots: { customRender: "images" },},
    { title: "热品", dataIndex: "isHot", width: 100, key: "isHot", scopedSlots: {customRender:'hot'}},
    { title: "新品", dataIndex: "isNew", width: 100, key: "isNew", scopedSlots: {customRender:'new'}},
    // { title: "产地", dataIndex: "location",  width:80, key: "location" },
    { title: "操作", key: "operation", fixed: "right", width: 100, scopedSlots: { customRender: "action" },},
  ];


  created() {
    this.form = this.$form.createForm(this, { name: "products" });
  }

  mounted() {
    this.listProducts();
    
  }



  // 后端API针对分页已经写好了两个参数，分别为limit, skip; limit是限制最多有多少货物，skip是跳过
  listProducts() {
    get("https://xuming.dynamicsales.cn/products", {
      limit: 2000,
      skip:0,
    }).then((data) => {
      console.log("获取数据信息", data.items)
      
      console.log("拿到热品数据", data.items[0].isHot)

      this.productsList = data.items;
      this.allProducts = data.items;
    });
  }

  // function isHot() {
  //   const isHotIds = record.value.hot.map((l) => l.id);
  //   return includes(isHotIds)
  // }

  // async function onChange(checked) {
  //   let payload = {
  //     isHot: checked,
  //   }
  // }

  async handleSubmit() {
    this.form.validateFields((err, values: ProductsInterface) => {
      if (!err) {
        if (this.editProductsId) {      
          console.log("添加数据信息")
          console.log(this.editProductsId)
          put("https://xuming.dynamicsales.cn/products/"+ this.editProductsId, {
            id1: this.editProductsId,
            ...values,
            images: values.images.map((i: any) =>
              i.url ? i.url.replace("https://xuming.dynamicsales.cn/products/uploadFile", "") : i.response.url
            ),
          }).then(() => {
            this.$message.success("编辑成功");
            this.listProducts();
            this.hideModal();
          });
        } else {
          post("https://xuming.dynamicsales.cn/products", {
            ...values,
            images: values.images.map((i: any) => i.response.url),
          }).then(async() => {
            this.$message.success("添加成功");
            this.listProducts();
            await this.sleep(500);
            this.hideModal();
          });
        }
      }
    });
  }

  normFile(e) {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  }

  checkPrice(rule, value, callback) {
    console.log("check数据信息")
    if (value > 0) {
      callback();
      return;
    }
    callback("价格必须大于0");
  }

  // 用到ant-design of vue 表单组件form, setFieldsValue, setFieldsValue: set the value of a field
  editProducts(product: ProductsInterface) {
    console.log("编辑数据信息")
    console.log(product)
    
    console.log("图片")
    console.log(product.images)
    this.addVisible = true;
    this.editProductsId = product.id;
    this.checkedHot = product.isHot
    this.checkedNew = product.isNew
    console.log("是热品吗?", product.isHot, this.checkedHot)
    console.log("是新品吗?", product.isNew, this.checkedNew)
    this.$nextTick(() => {
      this.form.setFieldsValue({
        ...product,
        images: product.images.map((i) => ({uid: i, url: (i.startsWith("https") ? "" : "https://xuming.dynamicsales.cn/products/uploadFile") + i, status: "done"})),
      });
    });
  }

  delProducts(product: ProductsInterface) {
    console.log("删除数据信息")
    console.log(product)
      
    this.$confirm({
      title: `确认删除${product.name}吗？`,
      content: "删除后无法恢复",
      onOk: () => {
        del("https://xuming.dynamicsales.cn/products/"+ product.id).then(async() => {
          this.$message.success("删除成功");
          await this.sleep(500);
          this.listProducts();
        });
      },
    });
  }

  hideModal() {
    this.addVisible = false;
    this.editProductsId = undefined;
    this.form.resetFields();
  }

  find() {
    console.log("查询数据信息")
    this.productsList = this.allProducts.filter((i) => {
      let f = false;
      if (( i.id == this.id1) || i.name.includes(this.id1)) {
        f = true;
      }
      return f;
    });
  }

  exportData() {
    const cols = this.columns.filter(
      (i) => !["id", "operation"].includes(i.key as string)
    );
    const data: any[] = [cols.map((i) => i.title)];
    this.allProducts.forEach((g) => {
      const d = cols.map((i) => {
        const v = g[i.key as string];
        // return i.key === "images" ? v.join() : v;
        return i.key === "images" ? v : v;
      });
      data.push(d);
    });
    exportXLSX(data);
  }

  sleep(ms: number) {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve('');
            }, ms)
        });
    }

  importData(file) {
    const cols = {};
    this.columns.forEach((c) => {
      cols[c.title] = c.key;
    });
    readXLSX(file, (res) => {
      const data: any[] = [];
      res.forEach((g) => {
        const d = {};
        Object.keys(g).forEach((k) => {
          d[cols[k]] = g[k] || "";
          if (cols[k] === "images") {
            d[cols[k]] = d[cols[k]].split(",");
          }
        });
        data.push(d);
      });
      post("https://xuming.dynamicsales.cn/products/batch-create", data).then(async() => {
        this.$message.success("添加成功");
        await this.sleep(500);
        this.listProducts();
      });
    });
    return true;
  }

}
