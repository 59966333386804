














































































import { Component, Vue } from "vue-property-decorator";
import { get, post } from "@/utils/request";
import moment from "moment";
import html2canvas from "html2canvas";

interface OrderInterface {
  _id: string;
  goods: any[];
  log: any[];
  customer: string;
  status: number;
}

@Component({
  name: "OrderDetail",
  components: {},
})
export default class OrderDetail extends Vue {
  moment = moment;
  order: any = {};
  STATUS = {
    "-1": "待修改",
    "0": "待审核",
    "1": "审核通过",
    "2": "排产",
    "3": "排单",
    "4": "发货",
    "5": "派车",
  };
  printVisible = false;
  columns = [
    { title: "名称", dataIndex: "name", key: "name" },
    {
      title: "图片",
      key: "img",
      width: 200,
      scopedSlots: { customRender: "img" },
    },
    { title: "类目", dataIndex: "category", key: "category" },
    { title: "描述", dataIndex: "description", key: "description" },
    { title: "箱规", dataIndex: "spec", key: "spec" },
    { title: "规格", dataIndex: "standards", key: "standards" },
    { title: "价格", dataIndex: "price", key: "price" },
    { title: "购买数量", dataIndex: "count", key: "count" },
  ];
  printColumns = [
    { title: "名称", dataIndex: "name", key: "name" },
    { title: "价格", dataIndex: "price", key: "price" },
    { title: "购买数量", dataIndex: "count", key: "count" },
  ];

  mounted() {
    this.getOrder();
  }

  getOrder() {
    get("/order/get?id=" + this.$route.query.id).then((data) => {
      this.order = data.order;
    });
  }

  print() {
    html2canvas(document.getElementById("print") as HTMLElement).then(function(
      canvas
    ) {
      const link = document.getElementById("link") as HTMLElement;
      link.setAttribute("href", canvas.toDataURL());
      link.setAttribute("download", "result.png");
      link.click();
    });
  }

  detail(order: OrderInterface) {
    this.$router.push(`/order/detail?id=${order._id}`);
  }

  changeStatus() {
    post("/order/status", {
      id: this.$route.query.id,
      status: this.order.status + 1,
      content: this.STATUS[this.order.status + 1],
    }).then(() => {
      this.$message.success("操作成功");
      this.getOrder();
    });
  }
}
