












































































































































































import { Component, Vue } from "vue-property-decorator";
import { get, post } from "@/utils/request";

export interface BusinessInterface {
  _id: string;
  name: string;
  business: string;
  password: string;
  logo: any;
}

@Component({
  name: "Customer",
})
export default class Customer extends Vue {
  addVisible = false;
  editId: string | undefined = undefined;
  form: any;
  userList = [];
  businessList = [];
  columns = [
    { title: "用户名", dataIndex: "name", key: "name" },
    { title: "地址", dataIndex: "addr", key: "addr" },
    { title: "联系方式", dataIndex: "tel", key: "tel" },
    { title: "账户余额", dataIndex: "account", key: "account" },
    { title: "信用额度", dataIndex: "credit", key: "credit" },
    { title: "业务员", key: "biz", scopedSlots: { customRender: "biz" } },
    {
      title: "操作",
      key: "operation",
      width: 140,
      scopedSlots: { customRender: "action" },
    },
  ];

  normFile(e) {
    console.log(e);

    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList && e.fileList.length >= 1
      ? [e.fileList[e.fileList.length - 1]]
      : [];
  }

  created() {
    this.form = this.$form.createForm(this, { name: "customer" });
  }

  mounted() {
    this.listUser();
    get("/user/list?type=biz").then((data) => {
      this.businessList = data.user;
    });
  }

  listUser() {
    get("/user/list?type=cus").then((data) => {
      this.userList = data.user;
    });
  }
  toDetail(id: string) {
    this.$router.push({ path: "/customer-detail", query: { id } });
  }
  handleSubmit() {
    this.form.validateFields((err, values: BusinessInterface) => {
      if (!err) {
        if (this.editId) {
          post("/user/edit", {
            id: this.editId,
            ...values,
            type: "cus",
            logo: values.logo.map((i: any) =>
              i.url
                ? i.url.replace("https://xuming.dynamicsales.cn/users", "")
                : i.response.imgPath
            )[0],
          }).then(() => {
            this.$message.success("修改成功");
            this.listUser();
            this.hideModal();
          });
        } else {
          post("/user/add", {
            ...values,
            type: "cus",
            logo: values.logo[0].response.imgPath,
          }).then(() => {
            this.$message.success("添加成功");
            this.listUser();
            this.hideModal();
          });
        }
      }
    });
  }

  editUser(user: BusinessInterface) {
    this.addVisible = true;
    this.editId = user._id;
    this.$nextTick(() => {
      this.form.setFieldsValue({
        ...user,
        logo: [
          {
            uid: user.logo,
            url:
              (user.logo.startsWith("http") ? "" : "https://xuming.dynamicsales.cn/users") +
              user.logo,
            status: "done",
          },
        ],
      });
    });
  }

  delUser(user: BusinessInterface) {
    this.$confirm({
      title: `确认删除${user.name}吗？`,
      content: "删除后无法恢复",
      onOk: () => {
        post("/user/del", { id: user._id }).then(() => {
          this.$message.success("删除成功");
          this.listUser();
        });
      },
    });
  }

  hideModal() {
    this.addVisible = false;
    this.form.resetFields();
  }

  checkNum(rule, value, callback) {
    if (value >= 0) {
      callback();
      return;
    }
    callback("必须大于等于0");
  }
}
