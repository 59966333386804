var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('a-table',{attrs:{"rowKey":"_id","columns":_vm.columns,"data-source":_vm.userList},scopedSlots:_vm._u([{key:"customers",fn:function(user){return _c('a-space',{},_vm._l((user.customers),function(cus){return _c('a-tag',{key:cus.name},[_vm._v(_vm._s(cus.name))])}),1)}},{key:"action",fn:function(user){return _c('a-space',{},[_c('a',{staticStyle:{"color":"red"},attrs:{"type":"link"},on:{"click":function($event){return _vm.delUser(user)}}},[_vm._v("删除")])])}}])}),_c('a-modal',{attrs:{"title":"添加业务员","visible":_vm.addVisible},on:{"cancel":_vm.hideModal,"ok":_vm.handleSubmit}},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'name',
            {
              rules: [
                {
                  required: true,
                  message: '请输入业务员姓名',
                } ],
            } ]),expression:"[\n            'name',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: '请输入业务员姓名',\n                },\n              ],\n            },\n          ]"}],attrs:{"placeholder":"请输入业务员姓名"}})],1),_c('a-form-item',{attrs:{"label":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'tel' ]),expression:"[\n            'tel',\n          ]"}],attrs:{"placeholder":"请输入业务员联系方式"}})],1)],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fr title"},[_c('span',[_vm._v("业务员管理")]),_c('div',{staticClass:"blank"})])}]

export { render, staticRenderFns }