
























































import { Component, Vue } from "vue-property-decorator";
import { get, post } from "@/utils/request";

interface OrderInterface {
  _id: string;
}

@Component({
  name: "Store",
  components: {}
})
export default class Store extends Vue {
  addVisible = false;
  form: any;
  editGoodsId: string | undefined = undefined;
  list = [];
  columns = [
    { title: "产品名称", dataIndex: "name", key: "name" },
    { title: "货品编号", dataIndex: "customer", key: "customer" },
    { title: "图片", dataIndex: "customer", key: "customer" },
    { title: "规格", dataIndex: "customer", key: "customer" },
    { title: "订单数量", dataIndex: "customer", key: "customer" },
    { title: "到货数量", dataIndex: "customer", key: "customer" },
    { title: "推荐车位", dataIndex: "customer", key: "customer" },
    {
      title: "操作",
      key: "operation",
      fixed: "right",
      width: 100,
      scopedSlots: { customRender: "action" }
    }
  ];
}
