
























































import { Component, Vue } from "vue-property-decorator";
import { get, post } from "@/utils/request";
import {BusinessInterface} from './Customer.vue'

@Component({
  name: "CustomerDetail"
})
export default class CustomerDetail extends Vue {
  customer: Partial<BusinessInterface> = {}
  passwordDialog = false
  form: any;
  flowList = []
  columns = [
    {title: '汇入时间', dataIndex: 'time', key: 'time'},
    {title: '贷款金额', dataIndex: 'money', key: 'money'},
    {title: '可用额度', dataIndex: 'total', key: 'total'},
    {title: '账户余额', dataIndex: 'account', key: 'account'},
    {title: '信用额度', dataIndex: 'credit', key: 'credit'},
    {
      title: '操作',
      key: 'operation',
      width: 100,
      scopedSlots: {customRender: 'action'},
    },
  ];
  getData(){
get('/user/get',this.$route.query).then(data=>{
      this.customer = data.user
    })
  }
  created(){
    this.form = this.$form.createForm(this, {name: "customer"})
    this.getData()
  }
  hideModal() {
    this.passwordDialog = false
    this.form.resetFields()
  }
   handleSubmit() {
    this.form.validateFields((err, values: any) => {
      if (!err) {
        console.log(values);
        if(values.password!==values.password2){
          this.$message.warn('密码需要一致')
          return
        }
        post('/user/edit',{id:this.customer._id,password:values.password}).then(()=>{
          this.getData()
          this.passwordDialog = false
        })
      }
    });
  }
  credit(row){
    console.log(row);
  }
}
