<template>
  <div id="app">
    <a-config-provider :locale="locale">
      <router-view/>
    </a-config-provider>
  </div>
</template>
<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';

export default {
  data() {
    return {
      locale: zhCN,
    };
  },
};
</script>
<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.fr {
  display: flex;
  align-items: center;
}

.blank {
  flex: 1;
}
.mt{
  margin-top: 22px;
}
.block{
  width: 100% !important;
}
.title {

  margin-bottom: 16px;

  & > span {
    font-weight: bold;
    font-size: 16px;
  }
}
.info > div {
  margin: 12px 0;
}
.space>*:not(:first-child){
  margin-left: 12px;
}
</style>

