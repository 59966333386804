import axios from "axios";
import { message } from "ant-design-vue";
import XLSX from "xlsx";
import moment from "moment";

axios.defaults.baseURL = "https://xuming.dynamicsales.cn";

let token = localStorage.getItem("token");

export function setToke(t: string) {
  token = t;
  localStorage.setItem("token", t);
}

const instance = axios.create({
  withCredentials: true,
});

/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */
export function get(url: string, params = {}): Promise<any> {
  return new Promise((resolve, reject) => {
    instance
      .get(url, {
        params: params,
        headers: { Authorization: token },
      })
      .then((response) => {
        
        resolve(response.data);

        // if (response.data.code === 0) {
          // resolve(response.data);
        // } else {
        //   if (response.data.err === "请先登录") {
        //     window.location.href = "/user";
        //   }
        //   reject(response.data.err);
        //   if (!url.startsWith("/user")) {
        //     message.error(response.data.err);
        //   }
        // }

      })
      .catch((err) => {
        reject(err);
      });
  });
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

// 这个地方需要注意的是, 原本是response.data.code === 0, 但后端设的不是code === 0, 而是 status === 'success'

export function post(url: string, data = {}): Promise<any> {
  return new Promise((resolve, reject) => {
    instance
      .post(url, data, {
        headers: { Authorization: token },
      })
      .then(
        (response) => {
            resolve(response.data);
        },
        (err) => {
          reject(err);
        }
      );
  });
}


export function login(url: string, data = {}): Promise<any> {
  return new Promise((resolve, reject) => {
    instance
      .post(url, data, {
        headers: { Authorization: token },
      })
      .then(
        (response) => {
          if (response.data.status === 'success') {
            resolve(response.data);
          } else {
            if (response.data.err === "请先登录") {
              window.location.href = "/user";
            }
            reject(response.data.err);
            message.error(response.data.err);
          }
        },
        (err) => {
          reject(err);
        }
      );
  });
}

/**
 * 封装delete方法
 * @param url
 * @param data
 * @returns {Promise}
 */
 export function del(url: string, params = {}): Promise<any> {
  return new Promise((resolve, reject) => {
    instance
      .delete(url, {
        params: params,
        headers: { Authorization: token },
      })
      .then((response) => {
        
        resolve(response.data);

        // if (response.data.code === 0) {
          // resolve(response.data);
        // } else {
        //   if (response.data.err === "请先登录") {
        //     window.location.href = "/user";
        //   }
        //   reject(response.data.err);
        //   if (!url.startsWith("/user")) {
        //     message.error(response.data.err);
        //   }
        // }

      })
      .catch((err) => {
        reject(err);
      });
  });
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

// 这个地方需要注意的是, 原本是response.data.code === 0, 但后端设的不是code === 0, 而是 status === 'success'

export function put(url: string, data = {}): Promise<any> {
  return new Promise((resolve, reject) => {
    instance
      .put(url, data, {
        headers: { Authorization: token },
      })
      .then(
        
        (response) => {
          // if (response.data.status === 'success') {
          resolve(response.data);
          // } else {
          //   if (response.data.err === "请先登录") {
          //     window.location.href = "/user";
          //   }
          //   reject(response.data.err);
          //   message.error(response.data.err);
          // }
        },
        (err) => {
          reject(err);
        }
      );
  });
}

export const exportXLSX = (data: any[] = [], prefix = "") => {
  function openDownloadDialog(url, saveName) {
    if (typeof url === "object" && url instanceof Blob) {
      url = URL.createObjectURL(url); // 创建blob地址
    }
    const downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.download = saveName;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  // 将一个sheet转成最终的excel文件的blob对象，然后利用URL.createObjectURL下载
  function sheet2blob(sheet, sheetName?) {
    sheetName = sheetName || "sheet1";
    const workbook = {
      SheetNames: [sheetName],
      Sheets: {}
    };
    workbook.Sheets[sheetName] = sheet;
    // 生成excel的配置项
    const wopts: XLSX.WritingOptions = {
      bookType: "xlsx", // 要生成的文件类型
      bookSST: false, // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
      type: "binary"
    };
    const wbout = XLSX.write(workbook, wopts);

    // 字符串转ArrayBuffer
    function s2ab(s) {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    }
    return new Blob([s2ab(wbout)], { type: "application/octet-stream" });
  }

  const sheet = XLSX.utils.aoa_to_sheet(data);

  openDownloadDialog(
    sheet2blob(sheet),
    `${prefix ? `${prefix}-` : ""}${moment().format("YYYYMMDDHHmmss")}.xlsx`
  );
};

export function readXLSX(file,cb) {
  const reader = new FileReader();
  reader.onload = function(e) {
    let workbook;
    let result = [];
    try {
      const data = e.target?.result;
      workbook = XLSX.read(data, {
        type: "binary"
      }); // 以二进制流方式读取得到整份excel表格对象
    } catch (e) {
      console.log("文件类型不正确");
      console.log(e);

      return;
    }
    // 表格的表格范围，可用于判断表头是否数量是否正确
    let fromTo = "";
    // 遍历每张表读取
    for (const sheet in workbook.Sheets) {
      // eslint-disable-next-line no-prototype-builtins
      if (workbook.Sheets.hasOwnProperty(sheet)) {
        fromTo = workbook.Sheets[sheet]["!ref"];
        console.log(fromTo);
        result = result.concat(
          XLSX.utils.sheet_to_json(workbook.Sheets[sheet])
        );
        break; // 如果只取第一张表，就取消注释这行
      }
    }
    cb(result)
  };
  reader.readAsBinaryString(file);
}
