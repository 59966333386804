import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Login from '../views/Login.vue'
import Home from '../views/Home.vue'
import Products from "@/views/Products.vue";
import Scheduling from "@/views/Scheduling.vue";
import Store from "@/views/Store.vue";
import Customer from "@/views/Customer.vue";
import CustomerDetail from "@/views/CustomerDetail.vue";
import SchedulingDetail from "@/views/SchedulingDetail.vue";
import Order from "@/views/Order.vue";
import OrderDetail from "@/views/OrderDetail.vue";
import Business from "@/views/Business.vue";
import Notice from "@/views/Notice.vue";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },

  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect:'/login',
    children:[
      {
        path: '/notice',
        name: 'Notice',
        component: Notice
      },
      {
        path: '/products',
        name: 'Products',
        component: Products
      },
      {
        path: '/store',
        name: 'Store',
        component: Store
      },
      {
        path: '/customer',
        name: 'Customer',
        component: Customer
      },
      {
        path: '/customer-detail',
        name: 'CustomerDetail',
        component: CustomerDetail
      },
      {
        path: '/business',
        name: 'Business',
        component: Business
      },
      {
        path: '/Scheduling',
        name: 'Scheduling',
        component: Scheduling
      },
      {
        path: '/Scheduling-detail',
        name: 'SchedulingDetail',
        component: SchedulingDetail
      },
      {
        path: '/order',
        name: 'Order',
        component: Order
      },
      {
        path: '/order-detail',
        name: 'OrderDetail',
        component: OrderDetail
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
